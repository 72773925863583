import { createClient } from 'urql'

// Defs
const accessToken = localStorage.getItem('accessToken') || process.env.REACT_APP_GRAPHCMS_AUTH_TOKEN

export default createClient({
	url: process.env.REACT_APP_GRAPHCMS_ENDPOINT,
	fetchOptions: {
		headers: {
			Authorization: `Bearer ${accessToken}`,
		},
	},
})

import React, { Suspense } from 'react'
import { createBrowserHistory } from 'history'
import { wrapHistory } from 'oaf-react-router'
import { Provider as UrqlProvider } from 'urql'
import gqlClient from 'utils/getGraphQlClient'
import AppCtx from 'contexts/App'

// Components
import App from 'components/site/App/App'
import SplashScreen from 'components/site/SplashScreen/SplashScreen'
import ErrorBoundary from 'components/containers/ErrorBoundary/ErrorBoundary'

// Data
import content from 'content.json'
import queries from 'queries'

// Config
import routes from 'config/routes'

// Init accessible routing
const history = createBrowserHistory()
wrapHistory(history, {
	smoothScroll: true,
})

//
export default function AppContainer() {
	return (
		<ErrorBoundary>
			<Suspense fallback={<SplashScreenContainer />}>
				<AppContainerInner />
			</Suspense>
		</ErrorBoundary>
	)
}

const AppContainerInner = () => {
	const appCtxValue = {
		content,
		queries,
	}

	return (
		<AppCtx.Provider value={appCtxValue}>
			<UrqlProvider value={gqlClient}>
				<App history={history} routes={routes} />
			</UrqlProvider>
		</AppCtx.Provider>
	)
}

const SplashScreenContainer = () => {
	return (
		<div data-restrain="max">
			<SplashScreen />
		</div>
	)
}

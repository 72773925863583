import raw from 'raw.macro'

export default {
	getUsers: raw('./queries/getUsers.gql'),
	getForums: raw('./queries/getForums.gql'),
	getThreads: raw('./queries/getThreads.gql'),
	getPosts: raw('./queries/getPosts.gql'),
	getHomePageData: raw('./queries/getHomePageData.gql'),
	getBoardViewData: raw('./queries/getBoardViewData.gql'),
	getBoardViewData2: raw('./queries/getBoardViewData2.gql'),
	getThreadViewData: raw('./queries/getThreadViewData.gql'),
	getThreadViewData2: raw('./queries/getThreadViewData2.gql'),
}

import React from 'react'
import s from './Button.module.scss'
import { Link, NavLink } from 'react-router-dom'

// Defs
const ELEMENT_MAP = {
	Link,
	NavLink,
}

/**
 * Versatile button component, used for everything that looks like a button (including links-styled-as-buttons).
 *
 * ### Implementation
 *
 * - Pass the variant name through the `kind` prop (defaults to `default` 😮)
 * - Pass element name through the `element` prop. Defaults to `a` when `href` is present, `button` otherwise.
 * - Also supports _react-router-dom_'s special `Link` and `NavLink` components
 *
 *   Example: `<Button element="NavLink" href="/products">`. The `href` prop is automatically converted to the required `to`.
 * - All other props are passed-through as-is
 * - Component's content is passed-through as-is
 */
export default function Button({ element, kind = 'default', children, className, ...props }) {
	const Element = ELEMENT_MAP[element] || element || (props.href ? 'a' : 'button')
	const type = Element === 'button' && !props.type ? 'button' : undefined

	if (element in ELEMENT_MAP) {
		props.to = props.href
		delete props.href
	}

	return (
		<Element data-component="Button" className={`${s.root} ${className || ''}`} type={type} data-kind={kind} {...props}>
			<span className={s.inner}>
				{kind !== 'plain' && <span className={s.effect} role="presentation" />}
				<span className={s.body}>{children}</span>
			</span>
		</Element>
	)
}

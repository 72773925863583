import React from 'react'

// Components
// import * as overrides from 'utils/markdown'

/**
 * Global config for `markdown-to-jsx`
 */
export default {
	wrapper: React.Fragment,
	overrides: {
		// a: overrides.A,
	},
}

// Polyfills
import 'focus-visible'
import 'inert-polyfill'
import smoothscroll from 'smoothscroll-polyfill'

//
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from 'components/containers/App'
import reportWebVitals from './reportWebVitals'

// Init ponyfills
smoothscroll.polyfill()

//
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

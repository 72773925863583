import React from 'react'

// Components
import Error from 'components/site/Error'

//
export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { error: false }

		try {
			// Reset error state upon navigation
			this.historyUnlisten = this.props.history.listen(() => this.setState({ error: false }))
		} catch (e) {}
	}

	componentDidCatch(error, info) {
		// console.log({ error })
		// console.log({ info })
	}

	componentWillUnmount() {
		this.historyUnlisten()
	}

	static getDerivedStateFromError(error) {
		return { error: error.message }
	}

	render() {
		if (this.state.error) {
			return <ErrorWrapper />
		}

		return this.props.children
	}
}

const ErrorWrapper = () => {
	return (
		<div data-restrain="max">
			<Error title="Oops" body="Yikes" />
		</div>
	)
}

import React from 'react'

// Mock data
import content from '../content.json'

//
export default React.createContext({
	content,
	queries: {},
})

import React, { useContext, useState } from 'react'
import AppCtx from 'contexts/App'
import s from './Introduction.module.scss'

// Components
import Collapsible from 'components/generic/Collapsible/Collapsible'
import Button from 'components/generic/Button/Button'
import Icon from 'components/generic/Icon/Icon'
import FormItem from 'components/generic/form/FormItem/FormItem'
import Markdown from 'components/generic/Markdown/Default/Default'

//
export default function Introduction() {
	const {
		content: { introduction: content },
	} = useContext(AppCtx)

	const accessToken = localStorage.getItem('accessToken')

	const [formData, setFormData] = useState({
		accessToken: '',
	})

	const onChange = ({ target: { name, value } }) => {
		setFormData({ ...formData, [name]: value })
	}

	const onSubmit = (event) => {
		event.preventDefault()
		localStorage.setItem('accessToken', formData.accessToken)
		window.location.reload()
	}

	return (
		<aside className={s.root} data-contentbox>
			<Collapsible expanded={!accessToken}>
				<header className={s.header}>
					<h2>{content.title}</h2>
					<Collapsible.Trigger>
						<Button className={s.trigger} kind="plain" title={content.triggerLabel}>
							<Icon className={s.trigger_icon} name="chevron-right" />
						</Button>
					</Collapsible.Trigger>
				</header>
				<Collapsible.Target>
					<div>
						<Markdown>{content.body}</Markdown>
						<form onSubmit={onSubmit}>
							<FormItem name="accessToken" label={content.passwordLabel} onChange={onChange} value={formData.accessToken} />
						</form>
					</div>
				</Collapsible.Target>
			</Collapsible>
		</aside>
	)
}

import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import s from './SiteHeader.module.scss'

// Assets
import gw2Logo from 'media/logo-rfe.png'

/**
 * The global site header.
 */
export default function SiteHeader() {
	return (
		<header className={s.root}>
			<div className={s.branding}>
				<Link to="/">
					<img className={s.logo} src={gw2Logo} alt="" />
				</Link>
				<p>RFE Forums Archive</p>
			</div>
			<nav data-gap="s">
				<NavLink to="/">Boards</NavLink>
				<NavLink to="/users">Users</NavLink>
			</nav>
		</header>
	)
}

import React from 'react'

export default [
	{
		path: '/',
		exact: true,
		Component: React.lazy(() => import('components/pages/Home')),
	},
	{
		path: '/boards/:forumid?',
		exact: true,
		Component: React.lazy(() => import('components/pages/Board')),
	},
	{
		path: '/boards/:forumid?/page/:page?',
		exact: true,
		Component: React.lazy(() => import('components/pages/Board')),
	},
	{
		path: '/boards/:forumid/threads/:threadid',
		exact: true,
		Component: React.lazy(() => import('components/pages/Thread')),
	},
	{
		path: '/boards/:forumid/threads/:threadid/page/:page',
		exact: true,
		Component: React.lazy(() => import('components/pages/Thread')),
	},
	{
		path: '/users',
		exact: true,
		Component: React.lazy(() => import('components/pages/Users')),
	},
	{
		title: 'Not found',
		Component: React.lazy(() => import('components/pages/NotFound')),
	},
]

import React, { useContext, useState } from 'react'
import uid from 'utils/uid'
import s from './Collapsible.module.scss'

// Defs
const Ctx = React.createContext({})

/**
 * Generic disclosure widget.
 */
export default function Collapsible({ id, expanded = false, children }) {
	const [localExpanded, setLocalExpanded] = useState(expanded)

	const ctxValue = {
		id: id || uid(),
		localExpanded,
		setLocalExpanded,
	}

	return <Ctx.Provider value={ctxValue}>{children}</Ctx.Provider>
}

const Trigger = ({ children }) => {
	const triggerEvents = ['Enter', ' ']
	const { id, localExpanded, setLocalExpanded } = useContext(Ctx)

	const onClick = (event) => setLocalExpanded(!localExpanded)
	const onKeyDown = (event) => triggerEvents.includes(event.key) && event.preventDefault()
	const onKeyUp = (event) => triggerEvents.includes(event.key) && setLocalExpanded(!localExpanded)

	return React.cloneElement(children, {
		onClick,
		onKeyDown,
		onKeyUp,
		'className': `${s.trigger} ${children.props.className || ''}`,
		'aria-expanded': !!localExpanded,
		'aria-controls': id,
		'tabIndex': 0,
	})
}

const Target = ({ children }) => {
	const { id, localExpanded } = useContext(Ctx)

	return React.cloneElement(children, {
		id,
		'className': `${s.target} ${children.props.className || ''}`,
		'aria-hidden': !localExpanded,
	})
}

Collapsible.Trigger = Trigger
Collapsible.Target = Target
